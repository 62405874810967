.comment-full {
    border: 1px solid #80808070;
    border-radius: 15px;
    margin: 13px 0px;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.cm-name {
    font-size: larger;
    font-weight: 600;
}

p.cm-email {
    color: gray;
    margin: 0px;
    font-size: smaller;
}

h1.cmmnt-hr {
    background-color: #8080808f;
    width: 100%;
    height: 1px;
}

p.cm-body {
    text-align: initial;
    margin-bottom: 28px;
}