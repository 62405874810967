.cart-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1;
    border: 1px solid #8080804a;
    width: 80%;
    border-radius: 10px;
    padding: 15px;
    margin: 8px 0px;
    cursor: pointer;
}

.cart-container {
    grid-template-columns: 1fr 1fr;
    margin: 20px 0px;
}

p.cart-title {
    text-align: center;
    font-weight: 600;
}

p.cart-text {
    text-align: initial;
    color: #636363;
}

hr.cart-hr {
    border: 0px solid;
    height: 1px;
    height: 1px;
    width: 95%;
    /* color: gray; */
    background-color: #8080807a;
}

a.user-link{
    text-decoration: none;
}

a.user-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}