.user-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    border-radius: 15px;
    padding: 0px 25px 15px;
    box-shadow: 0px 0px 10px 1px #80808073;
}



h1.name{
    margin-bottom: 15px;
    font-size: larger;
}

p.username {
    color: gray;
    margin: 0px;
}

h1.user-hr {
    height: 1px;
    width: 98%;
    background-color: #b7b7b7ab;
    margin-bottom: 8px;
}
